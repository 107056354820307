<!--添加题目-->
<template>
  <div class="main">
     <div style="font-size: 26px; font-weight: bold;display:flex"><div style="color:#C8C8C8;cursor: pointer;" @click='goback'>&lt;返回</div><div>|</div><div>美容师详情</div></div>
     <div style="display: flex;justify-content: space-between;margin: 20px 0;">
         <div style="width: 25%;padding: 20px;background:#fff;border-radius:14px">
            <div style="display:flex;align-items:center"><div style="width:11px;height:24px;border-radius:63px;background:#1E5846;margin-right:10px"></div><div style="font-size:24px;font-weight:bold">美容师详情</div></div>
            <div style="margin: 20px 0;">姓名：{{row.name}}</div>
            <div style="margin: 20px 0;">等级：{{row.levelName}}</div>
            <div style="margin: 20px 0;">年龄：{{age}}</div>
            <div style="margin: 20px 0;">总学习时间：{{row.studyTimeMinutes | stime}}</div>
            <div>门店：{{row.storeName}}</div>
         </div>
         <div style="width: 65%;padding: 20px;background:#fff;border-radius:14px;" >
          <!--正在学习-->
            <div style="border: 1px solid #ADE0B9;padding: 20px;margin: 20px 0;border-radius:14px;background:#F4FBF4" v-for="(item,index) in des" :key="index">
              <div style="display:flex;justify-content:space-between;align-items:flex-end">
                <div style="color:#64A774;font-size:40px;">{{item.levelName}}</div>
                <div style="color:#64A774;font-size:24px">正在学习</div>
              </div>
              <div style="margin: 20px 0;">等级学习时间：{{item.startTime}}-{{item.endTime?item.endTime:''}}</div>
              <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                  <div>通关分数 {{item.paperScore}}</div>
                  <div>通关资料：暂无</div>
              </div>
              <div style="margin: 20px 0;">已学进度：{{item.contentCountStudy}}/{{item.contentCount}}个课程</div> 
              <div>学习时长{{item.studyTimeMinutes}}</div>
            </div>
          <!--历史记录-->
             <div style="border: 1px solid #E0E0E0;padding: 20px;margin: 20px 0;border-radius:14px;" v-for="(item,index) in des2" :key="index" >
              <div style="display:flex;justify-content:space-between;align-items:flex-end">
                <div style="color:#BABABA;font-size:40px;">{{item.levelName}}</div>
                <div style="color:#BABABA;font-size:24px">已学习结束</div>
              </div>
              
              <div style="margin: 20px 0;">等级学习时间：{{item.startTime}}-{{item.endTime?item.endTime:''}}</div>
              <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                  <div>通关分数：{{item.paperScore}}</div>
                  <div>通关资料：暂无</div>
              </div>
              <div style="margin: 20px 0;">已学进度：{{item.contentCountStudy}}/{{item.contentCount}}个课程</div>
              <div>学习时长{{item.studyTimeMinutes}}</div>
            </div>
            
            <!-- <div style="display: flex;">
                <div style="margin-left: 50px;font-size:40px;color:#64A774">{{row.levelName}}</div>
            </div>
            <div style="border: 1px solid rgb(187, 187, 187);padding: 20px;margin: 20px 0;" v-for="(item,index) in des" :key="index">
                <div style="display: flex;justify-content: space-between;">
                    <div>{{row.levelName}}</div>
                    <div>正在学习</div>
                </div>
                <div style="margin: 20px 0;">等级学习时间：{{item.startTime}}</div>
                <div style="display: flex;justify-content: space-between;">
                    <div style="width: 50%;">
                        <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                            <div>通关分数：{{item.paperScore?item.paperScore:'暂无'}}</div>
                            <div>通关资料：暂无</div>
                        </div>
                        <div>医学进度：{{item.contentCountStudy}}个/{{item.contentCount}}个课程</div>
                    </div>
                    <div style="width: 50%;">
                    </div>
                </div>
            </div>
            
              
             <div style="border: 1px solid rgb(187, 187, 187);padding: 20px;margin: 20px 0;" v-for="(item,index) in des2" :key="index" v-if="des2">
                <div style="display: flex;justify-content: space-between;">
                    <div>{{item.levelName}}</div>
                    <div>已结束</div>
                </div>
                <div style="margin: 20px 0;">等级学习时间：{{item.startTime}}-{{item.endTime}}</div>
                <div style="display: flex;justify-content: space-between;">
                    <div style="width: 50%;">
                        <div style="display: flex;justify-content: space-between;margin: 20px 0;">
                            <div>通关分数：{{item.paperScore?item.paperScore:'暂无'}}</div>
                            <div>通关资料：</div>
                        </div>
                        <div>医学进度：{{item.contentCountStudy}}个/{{item.contentCount}}个课程</div>
                    </div>
                    <div style="width: 50%;">
                        <img src="../../../src/images/bg.png" alt="" style="width: 100%;">
                    </div>
                </div>
            </div> -->
         </div>
     </div>
  </div>
</template>

<script>
import {beauticianDetails} from "../../apis/index";
var COS = require('cos-js-sdk-v5');
export default {
  data() {
    return {
      des:[],
      des2:[],
      row:{},
      age:''
    }
  },
  filters:{
    stime(value){
      return parseInt(value/60) + '小时'+value%60 + '分'
    }
  },
 
  created(){
    let row=JSON.parse(this.$route.query.row)
    this.row=row
    this.getDes()
    
  },
  methods:{
    getAge(str){
        var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})/);
        if (r == null) return false;

        var d = new Date(r[1], r[3] - 1, r[4]);
        var returnStr = "输入的日期格式错误！";

        if (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]) {

            var date = new Date();
            var yearNow = date.getFullYear();
            var monthNow = date.getMonth() + 1;
            var dayNow = date.getDate();

            var largeMonths = [1, 3, 5, 7, 8, 10, 12], //大月， 用于计算天，只在年月都为零时，天数有效
                lastMonth = monthNow - 1 > 0 ? monthNow - 1 : 12,  // 上一个月的月份
                isLeapYear = false, // 是否是闰年
                daysOFMonth = 0;    // 当前日期的上一个月多少天

            if ((yearNow % 4 === 0 && yearNow % 100 !== 0) || yearNow % 400 === 0) {  // 是否闰年， 用于计算天，只在年月都为零时，天数有效
                isLeapYear = true;
            }
            var Y = yearNow - parseInt(r[1]);
            var M = monthNow - parseInt(r[3]);
            var D = dayNow - parseInt(r[4]);
            if (D < 0) {
                M--;
            }
            if (M < 0) {  // 借一年 12个月
                Y--;
            }
            if (Y < 0) {
                returnStr = "出生日期有误！";

            } else {
                returnStr = Y + "岁";
            }

        }
        return returnStr;
    },
      goback() {
      this.$router.go(-1);
    },
    getDes(){
      let row=this.row
      const params={
        brandId:row.brandId,
        levelId:row.levelId,
        userId:row.userId
      }
      beauticianDetails(params).then(res=>{
        console.log(res.data.data)
        if(res.data.code==200){
          this.des=res.data.data.slice(0,1)
          this.des2=res.data.data.slice(1)
          let str=this.getAge(this.des[0].birthDay)
          this.age=str
        }
      })
    },
  }
  
};
</script>

<style lang='less' scoped>
.main {
  // background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.title {
  display: flex;
  // justify-content: space-between;
  align-items: flex-start;
  div {
    display: flex;
  }
  .line {
    width: 9px;
    height: 22px;
    border-radius: 6px;
    background: #409eff;
    margin-right: 11px;
  }
}
.title > div {
  width: 50%;
  margin: 29px 0;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  //   align-items: center;
}
.titleleft {
  display: flex;
  flex-direction: column;
}
/deep/.el-form-item__label {
  width: 100px;
}
/deep/.el-radio__label,
/deep/.el-checkbox__label {
  display: flex;
  align-items: center;
}
</style>
